.headerCol{
    position: fixed !important;
    top: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 75px;
    padding: 0px 40px;
    z-index: 100;
    background-color: #fff;
    -webkit-box-shadow: 0px 9px 10px -11px #0000004e; 
    box-shadow: 0px 9px 10px -11px #0000004e; 
    
    
}


.gr_text{
    color:#16697a;
    font-size: 22px;
    font-weight: bold;
    margin: 10px;
   
}

.headerLink{
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    margin-left: 32px;
    transition: all 0.25s ease 0s;
    
}

.headerLink:hover{
    color: #FFA62B !important;
}


.loginButtonItem{
   background-color: #16697a !important;
    border-radius: 8px !important;
    transition: all 0.25s ease 0s;
    
}
.loginButtonItem:hover{
    background: #82C0CC !important;
}
.logoImage{
    height: 60px;
}