
.page_title_container{
    background-color: #2b486c ;
    min-height: 100px;
    margin-top: 70px;
    padding-top: 20px;

    
}

.page_title_container h1{
    text-align: center;
    font-size: 40px;
    color: #fff;
}

.loginButton{
    background-color: #16697A !important;
    text-shadow: 2px 2px 2px #000000;
}

.loginButton:hover{
    background-color: #82C0CC !important;
}




@media (max-width: 575px) {
    .bottomContainer div h1{
            font-size: 4vw;
        }
    
}